// @flow
import type { Styles } from './types';
import sanitizePrismicBackgroundColors from './helpers/sanitizePrismicBackgroundColors';

import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const getBackgroundColor = theme => (color: string) =>
  ({
    primary: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
    white: theme.palette.common.white,
    grey: '#F7F7F7',
  }[sanitizePrismicBackgroundColors(color) || 'grey']);

const styles: Styles = theme => ({
  root: {},
  wrapper: ({ data }) => ({
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(6)}px`,
    },
    background: getBackgroundColor(theme)(
      data.primary?.slice_background_image_color || '',
    ),
  }),
  itemsGridContainer: {},
  itemPrimaryWrapper: {},
  itemSecondaryWrapper: {
    textAlign: 'left',
  },
  title: ({ data }) => ({
    ...(data.primary?.slice_background_color === 'Primary' ||
    data.primary?.slice_background_image_color === 'Primary'
      ? { color: theme.palette.common.white }
      : { color: theme.palette.common.black }),
  }),
  description: ({ data }) => ({
    '* + &': {
      marginTop: theme.spacing(2),
      ...(data.primary?.slice_background_color === 'Primary' ||
      data.primary?.slice_background_image_color === 'Primary'
        ? { color: theme.palette.common.white }
        : { color: 'inherit' }),
    },
  }),
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: `calc(100% + ${theme.spacing(1)}px)`,
    paddingTop: theme.spacing(4),
    margin: theme.spacing(-1),
  },
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  image: ({ data }) => ({
    ...(data.primary?.overlapping_image
      ? {
          width: '115%',
          [theme.breakpoints.down('sm')]: { width: '100%' },
        }
      : { width: '100%' }),
  }),
  imageOverlapping: {
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(-getBreakPointFactor(breakpoint) * 2),
        },
      }),
      {},
    ),
  },
  titleWrapper: ({ data }) => ({
    ...(data.primary?.slice_price && data.primary?.slice_price?.text
      ? {
          display: 'flex',
          justifyContent: 'space-between',
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
          },
        }
      : null),
  }),
  priceWrapper: {
    width: '100%',
    height: 'fit-content',
    maxWidth: 100,
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
    margin: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  price: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
});

export default styles;
