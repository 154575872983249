// @flow
import type { Styles } from './types';

const styles: Styles = unusedTheme => ({
  root: {
    paddingBottom: 0,
  },
  sliceTitle: {},
  diagramAnimation: {},
});

export default styles;
