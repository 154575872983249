// @flow
import type { Styles } from './types';
import sanitizePrismicBackgroundColors from './helpers/sanitizePrismicBackgroundColors';
import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const getBackgroundColor = theme => (color: string) =>
  ({
    primary: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
    white: 'rgba(255,255,255,0.8)',
    grey: '#F7F7F7',
  }[sanitizePrismicBackgroundColors(color) || 'grey']);

const getBackgroundImageColorGradient = theme => (gradientColor: string) =>
  ({
    primary: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
    white: theme.palette.common.white,
    grey: 'rgba(0,0,0,0.35)',
  }[sanitizePrismicBackgroundColors(gradientColor) || 'grey']);

const styles: Styles = theme => ({
  root: {},
  wrapper: ({ data }) => ({
    position: 'relative',
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(6)}px`,
    },
    ...(data.primary?.slice_background_image &&
    data.primary?.slice_background_image?.localFile?.url
      ? {
          background: `url(${data.primary?.slice_background_image?.localFile
            ?.url || ''}) no-repeat center center`,
          backgroundSize: 'cover',
        }
      : {
          backgroundColor: getBackgroundColor(theme)(
            data.primary?.slice_background_image_color || '',
          ),
        }),
  }),
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    opacity: 0.5,
    [`@supports (mix-blend-mode: multiply)`]: {
      opacity: 1,
      mixBlendMode: 'multiply',
    },
    background: ({ data }) =>
      getBackgroundImageColorGradient(theme)(
        data.primary?.slice_background_image_color || '',
      ),
  },
  itemsGridContainer: {},
  itemPrimaryWrapper: {
    textAlign: 'left',
    zIndex: 1,
  },
  itemSecondaryWrapper: {
    position: 'relative',
    padding: '0px !important',
  },
  title: {
    color: theme.palette.common.black,
    '& b, & strong': {
      color: theme.palette.primary.main,
    },
    '$wrapper$colorPrimary &': {
      color: theme.palette.common.white,
    },
    '$wrapper$colorWhite &': {
      color: theme.palette.common.black,
    },
    '$wrapper$colorGrey &': {
      color: theme.palette.common.black,
    },
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(2),
      color: 'inherit',
      '$wrapper$colorPrimary &': {
        color: theme.palette.common.white,
      },
      '$wrapper$colorWhite &': {
        color: theme.palette.common.black,
      },
      '$wrapper$colorGrey &': {
        color: theme.palette.common.black,
      },
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: `calc(100% + ${theme.spacing(1)}px)`,
    paddingTop: theme.spacing(4),
    margin: theme.spacing(-1),
  },
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  overlappingImage: {
    width: '100%',
    bottom: theme.spacing(-2),
    [theme.breakpoints.up('sm')]: {
      width: '110%',
      position: 'absolute !important',
      bottom: theme.spacing(-5),
      ...theme.breakpoints.keys.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [theme.breakpoints.up(breakpoint)]: {
            right: theme.spacing(-getBreakPointFactor(breakpoint) * 2),
          },
        }),
        {},
      ),
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '85%',
    },
  },
  colorPrimary: {},
  colorWhite: {},
  colorGrey: {},
});

export default styles;
