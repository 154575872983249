// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  sliceTitle: {
    textAlign: 'center',
  },
  itemsGridContainer: {},
  itemsGridItem: {},
  itemButton: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

export default styles;
