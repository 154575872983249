// @flow
import type { Styles } from './types';

const styles: Styles = unusedTheme => ({
  root: {},
  sliceTitle: {},
  itemGridContainer: {},
  itemGridItem: {},
  itemCard: {
    width: '100%',
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 4px -1px rgba(0,0,0,0.1), 0px 6px 8px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
});

export default styles;
