// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';

import DownloadForm from '~components/DownloadForm';
import useDownloadForm from '~components/DownloadForm/useDownloadForm';
import useDownloadFormValidationSchema from '~components/DownloadForm/useDownloadFormValidationSchema';
import RichText from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import getLinkedWhitePaperType from '~helpers/getLinkedWhitePaperType';

import type { Props } from './types';
import styles from './styles';
import cleanseZohoData from '../../../functions/helpers/cleanseZohoData';

const FORM_NAME = 'Guide';

const DownloadFormSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const downloadForm = useDownloadForm();
  const whitePaperData = getLinkedWhitePaperType(data.primary?.slice_document);

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Grid
        container
        spacing={isSmUp ? 7 : 5}
        alignItems="center"
        className={classes.itemsGridContainer}
      >
        <Grid item xs={12} sm={6} className={classes.itemPrimaryWrapper}>
          <RichText {...data.primary?.slice_title} className={classes.title} />
          <RichText
            {...data.primary?.slice_description}
            className={classes.description}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DownloadForm
            formName={FORM_NAME}
            node={downloadForm}
            listKey={data?.primary?.zoho_listkey || undefined}
            contentSource={
              cleanseZohoData(data?.primary?.zoho_lead_source) || undefined
            }
            inputSector={
              cleanseZohoData(data?.primary?.zoho_industry) || undefined
            }
            validationSchema={useDownloadFormValidationSchema(downloadForm)}
            sliceColor={data.primary?.slice_background_color || ''}
            fileUrl={whitePaperData?.data?.document_link?.url || ''}
            classes={React.useMemo(
              () => ({
                root: classes.downloadForm,
              }),
              [classes],
            )}
          />
        </Grid>
      </Grid>
    </Component>
  );
};

DownloadFormSlice.defaultProps = {
  className: undefined,
};

export const StyledDownloadFormSlice = withStyles<*, *, Props>(styles, { name: 'DownloadFormSlice' })(
DownloadFormSlice,
);
export default withBodySectionSlice<
  React.ElementConfig<typeof StyledDownloadFormSlice>,
>()(StyledDownloadFormSlice);
