// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  sliceTitle: {},
  itemsGridContainer: {},
  itemsGridItem: {},
  itemButtonWrapper: {
    '& .MuiButton-label': {
      justifyContent: 'space-between',
    },
    '&:hover': {
      filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3))',
    },
  },
  itemButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  itemIcon: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
      '$itemButton:hover &': {
        display: 'inline-flex',
      },
    },
  },
});

export default styles;
