// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  sliceTitle: {},
  itemsGridContainer: {},
  itemsGridItem: {
    '& .react-reveal': {
      height: '100%',
    },
  },
  itemCard: {
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 4px -1px rgba(0,0,0,0.1), 0px 6px 8px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
  itemCardWrapper: {
    height: '100%',
    display: 'flex',
    '& .MuiCardActionArea-focusHighlight': {
      backgroundColor: 'transparent',
      opacity: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  itemCardMedia: {
    minWidth: 150,
    height: 'inherit',
    flex: '1 auto',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxHeight: 150,
    },
    '& img': {
      transition: 'all .3s !important',
      '-webkit-transition': 'all .3s !important',
    },
    '$itemCardWrapper:hover &': {
      '& img': {
        transform: 'scale(1.05)',
      },
    },
  },
  itemCardContent: {
    textAlign: 'left',
    flex: '1 auto',
  },
  itemTitle: {
    color: theme.palette.common.black,
    '$itemCardWrapper:hover &': {
      color: theme.palette.primary.main,
    },
  },
  itemDescription: {
    '* + &': {
      marginTop: theme.spacing(1),
    },
    '& > *': {
      ...theme.typography.body2,
    },
  },
});

export default styles;
