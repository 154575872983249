// @flow
import flow from 'lodash/fp/flow';
import unescape from 'lodash/fp/unescape';

import type { PrismicStructuredTextType } from '~schema';

export default function sanitizeEmbeddedHtmlFieldValue(
  richTextField?: ?PrismicStructuredTextType,
) {
  return flow(
    ({ html, text }) => text || html,
    html => html && html.replace(/^<pre.*?>/, ''),
    html => html && html.replace(/<\/pre>$/, ''),
    html => html && unescape(html),
  )(richTextField || {});
}
