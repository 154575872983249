// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowForward from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import SliceTitle from '~components/SliceTitle';
import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const SolutionsSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              className={classes.itemsGridItem}
            >
              <ButtonBase
                component={FieldLink}
                field={item.item_link}
                aria-label={item.item_link_label?.text}
                className={classes.itemButtonBase}
              >
                <div className={classes.itemTextWrapper}>
                  <Fade bottom cascade>
                    <div className={classes.itemText}>
                      <RichText
                        {...item.item_title}
                        className={classes.itemTitle}
                      />
                      <RichText
                        {...item.item_description}
                        className={classes.itemDescription}
                      />
                    </div>
                    <div className={classes.itemIcon}>
                      <ArrowForward />
                    </div>
                  </Fade>
                </div>
                <NodeImage
                  data={item.item_background_image}
                  className={classes.itemBackgroundImage}
                />
              </ButtonBase>
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Container>
        <SliceTitle
          title={data.primary?.slice_title}
          description={data.primary?.slice_description}
          className={classes.sliceTitle}
          idGeneration="title"
        />
      </Container>
      <Grid container className={classes.itemsGridContainer}>
        {renderedItems}
      </Grid>
    </Component>
  );
};

SolutionsSlice.defaultProps = {
  className: undefined,
};

export const StyledSolutionsSlice = withStyles<*, *, Props>(styles, { name: 'SolutionsSlice' })(
SolutionsSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledSolutionsSlice>,
  // $FlowFixMe --> Reason: property `disableGutters` is missing in  `React.ElementConfig`
>({ ContainerProps: { maxWidth: false, disableGutters: true } })(
  StyledSolutionsSlice,
);
