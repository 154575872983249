// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: ({ data }) => ({
    ...(data?.primary?.video_width === 'Fullwidth'
      ? {
          padding: 0,
        }
      : {}),
  }),
  videoWrapper: {},
  video: {
    paddingBottom: '56.2%',
    position: 'relative',
    '& > *': {
      position: 'absolute',
    },
  },
  playButton: {
    '& svg': {
      [theme.breakpoints.up('md')]: {
        fontSize: 100,
      },
      fontSize: 62,
    },
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  playIcon: { fill: theme.palette.common.white },
  caption: {
    paddingTop: theme.spacing(1.5),
  },
});

export default styles;
