// @flow
import type { PrismicLinkType } from '~schema';

export default function getLinkedWhitePaperType(
  link: ?PrismicLinkType,
) {
  const document = link && link.document;
  return document && document.__typename === 'PrismicWhitePaper'
    ? document
    : undefined;
}
