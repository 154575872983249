// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  sliceTitle: {
    textAlign: 'initial',
  },
  carousel: {
    position: 'relative',
  },
  carouselItem: { position: 'relative', display: 'flex' },
  itemButton: {
    width: '100%',
    height: '100%',
    display: 'block',
    '& .itemIcon': {
      minWidth: '100%',
    },
  },
  itemIcon: {
    height: '100%',
    '& img': {
      objectFit: 'contain !important',
    },
  },
  itemName: {
    paddingTop: theme.spacing(1.5),
  },
  buttonBack: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    border: 'none',
    background: theme.palette.primary.light
  },
  buttonNext: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    border: 'none',
    background: theme.palette.primary.light
  },
});

export default styles;
