// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';

import SliceTitle from '~components/SliceTitle';
import StyledCard from '~components/StyledCard';
import { hasRichTextFieldValue } from '~plugins/prismic/components/RichText';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const ContentGridSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              className={classes.itemGridItem}
            >
              <StyledCard
                title={item?.item_title}
                description={item?.item_description}
                image={item?.item_image}
                link={item?.item_link}
                label={item?.item_label}
                className={classes.itemCard}
                cardActionAreaProps={{
                  component: FieldLink,
                  disableTouchRipple: true,
                  field: item?.item_link,
                }}
              />
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      {hasRichTextFieldValue(data?.primary?.slice_title) ||
      hasRichTextFieldValue(data?.primary?.slice_description) ? (
        <SliceTitle
          title={data?.primary?.slice_title}
          description={data?.primary?.slice_description}
          className={classes.sliceTitle}
          idGeneration="title"
        />
      ) : (
        undefined
      )}
      <Grid
        container
        spacing={5}
        alignItems="stretch"
        className={classes.itemGridContainer}
      >
        {renderedItems}
      </Grid>
    </Component>
  );
};

ContentGridSlice.defaultProps = {
  className: undefined,
};

export const StyledContentGridSlice = withStyles<*, *, Props>(styles, { name: 'ContentGridSlice' })(
ContentGridSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledContentGridSlice>,
>()(StyledContentGridSlice);
