// @flow
import * as React from 'react';
import classnames from 'classnames';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import loadable from '@loadable/component';
import Container from '@material-ui/core/Container';

import SliceTitle from '~components/SliceTitle';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';
import styles from './styles';
import type { Props } from './types';

const DiagramAnimation = loadable(() => import('~components/DiagramAnimation'));

const AnimationSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        className={classes.sliceTitle}
        idGeneration="title"
      />
      <DiagramAnimation className={classes.diagramAnimation} />
    </Component>
  );
};

export const StyledAnimationSlice = withStyles<*, *, Props>(styles, { name: 'AnimationSlice' })(
AnimationSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledAnimationSlice>,
>()(StyledAnimationSlice);
