// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import classnames from 'classnames';

import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';
import NodeImage from '~plugins/prismic/components/NodeImage';
import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import { hasRichTextFieldValue } from '~plugins/prismic/components/RichText';
import sanitizeImageWidth from './helpers/sanitizeImageWidth';

import type { Props, ImageWidth } from './types';
import styles from './styles';

const SingleImageSlice = ({
  data,
  node,
  className,
  classes,
  component,
  ContainerProps,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  const getContainerWidth = (width: ImageWidth | null) => {
    const containerWidth =
      width &&
      {
        Fullwidth: {
          maxWidth: false,
          disableGutters: true,
        },
        Contained: undefined,
      }[sanitizeImageWidth(width) || 'Contained'];
    return containerWidth === undefined ? undefined : containerWidth;
  };

  return (
    <Component
      className={classnames(classes.root, className)}
      ContainerProps={React.useMemo(() =>
        getContainerWidth(data.primary?.image_width || 'Contained'),
      )}
      {...props}
    >
      <div className={classes.imageWrapper}>
        <NodeImage
          data={data?.primary?.slice_image}
          className={classes.image}
        />
        {hasRichTextFieldValue(data?.primary?.slice_caption) ? (
          <Container>
            <RichTextTypography
              {...data?.primary?.slice_caption}
              variant="caption"
              className={classes.caption}
            />
          </Container>
        ) : null}
      </div>
    </Component>
  );
};

SingleImageSlice.defaultProps = {
  className: undefined,
};

export const StyledSingleImageSlice = withStyles<*, *, Props>(styles, { name: 'SingleImageSlice' })(
SingleImageSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledSingleImageSlice>,
>({ paddingFactor: [2, 0] })(StyledSingleImageSlice);
