// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  imageWrapper: {},
  image: {
    maxHeight: 600,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 300,
    },
  },
  caption: {
    textAlign: 'left',
    paddingTop: theme.spacing(1.5),
  }
});

export default styles;
