// @flow
/* eslint-disable no-use-before-define */
import * as React from 'react';

import useGroupedSlices, {
  type Config as UseGroupedSlicesConfig,
} from './useGroupedSlices';

export type Config = {|
  slices: $ElementType<UseGroupedSlicesConfig, 'slices'>,
  sliceType: string,
  groupedType?: string,
|};

export default function useGroupedSlicesByType({
  slices,
  sliceType,
  groupedType = `${sliceType}_grouped`,
}: Config) {
  return useGroupedSlices({
    slices,
    shouldGroup: React.useCallback(
      ({ slice_type }) => slice_type === sliceType,
      [sliceType],
    ),
    getGroupedType: React.useCallback(() => groupedType, [groupedType]),
  });
}
