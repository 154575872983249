// @flow
import type { Styles } from './types';

import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {},
  itemPrimaryWrapper: ({ data }) => ({
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      ...(data.primary?.image_position === 'Before'
        ? {
            order: 2,
          }
        : {}),
    },
  }),
  itemSecondaryWrapper: {},
  textWrapper: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5),
      paddingTop: theme.spacing(3),
    },
  },
  title: {
    color: theme.palette.common.black,
    '& b, & strong': {
      color: theme.palette.primary.main,
    },
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(2),
    },
  },
  image: {
    width: ({ data }) => (data.primary?.overlapping_image ? '110%' : '100%'),
    minHeight: 250,
    [theme.breakpoints.up('md')]: {
      minHeight: 300,
    },
  },
  imageOverlapping: ({ data }) => ({
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          ...(data.primary?.image_position === 'After'
            ? { marginLeft: theme.spacing(getBreakPointFactor(breakpoint) * 2) }
            : {
                marginLeft: theme.spacing(-getBreakPointFactor(breakpoint)) * 2,
              }),
        },
      }),
      {},
    ),
  }),
  listContainer: {},
  itemsGridItem: {},
  itemButton: {
    width: '100%',
    display: 'flex',
  },
  itemButtonContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
  },
  itemImage: {
    width: '100%',
    height: 'auto',
    maxWidth: 30,
    [theme.breakpoints.up('md')]: {
      maxWidth: 40,
    },
  },
  itemText: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.black,
    textAlign: 'left',
    '$itemButton:hover &': {
      color: theme.palette.primary.main,
    },
  },
});

export default styles;
