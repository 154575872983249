// @flow
import type { Styles } from './types';

import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {},
  wrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  primaryWrapper: {
    textAlign: 'left',
    width: '50%',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: theme.spacing(0),
      paddingBottom: theme.spacing(4),
    },
  },
  itemsGridContainer: {},
  itemsGridItem: {},
  itemsGridItemWrapper: {
    display: 'flex',
  },
  textWrapper: ({ data }) => ({
    ...(data.items?.length ? { marginBottom: theme.spacing(4) } : null),
  }),
  title: {
    color: theme.palette.common.black,
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(2),
    },
  },
  itemImage: {
    width: '100%',
    height: 'fit-content',
    maxWidth: 40,
    marginRight: theme.spacing(2),
  },
  itemTextWrapper: {},
  itemTitle: {
    color: theme.palette.common.black,
  },
  itemDescription: {
    '* + &': {
      marginTop: theme.spacing(1),
    },
  },
  modelViewer: {
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up('md')]: {
          marginLeft: '0px !important',
          marginBottom: `${theme.spacing(
            -getBreakPointFactor(breakpoint) * 2,
          )}px !important`,
          marginTop: theme.spacing(-getBreakPointFactor(breakpoint) * 2),
          marginRight: theme.spacing(-getBreakPointFactor(breakpoint)),
          width: `calc(50% + ${theme.spacing(
            getBreakPointFactor(breakpoint) * 2,
          )}px) !important`,
          height: 'auto',
          minHeight: 600,
        },
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(-getBreakPointFactor(breakpoint)),
          width: `calc(100% + ${theme.spacing(
            getBreakPointFactor(breakpoint) * 2,
          )}px)`,
        },
        marginLeft: theme.spacing(-getBreakPointFactor(breakpoint) / 2),
        marginBottom: theme.spacing(-getBreakPointFactor(breakpoint) * 1.3),
        width: `calc(100% + ${theme.spacing(
          getBreakPointFactor(breakpoint),
        )}px)`,
        height: 450,
      }),
      {},
    ),
  },
});

export default styles;
