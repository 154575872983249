// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import classnames from 'classnames';
import compact from 'lodash/compact';
import map from 'lodash/map';
import Fade from 'react-reveal/Fade';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import type {
  PrismicStructuredTextType,
  PrismicImageType,
  PrismicLinkType,
} from '~schema';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'primaryWrapper'
  | 'secondaryWrapper'
  | 'textWrapper'
  | 'title'
  | 'description'
  | 'image'
  | 'itemsGridContainer'
  | 'itemsGridItem'
  | 'itemButton'
  | 'itemButtonContent'
  | 'itemImage'
  | 'itemText';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  data: {
    primary: ?{
      slice_tab_name?: ?PrismicStructuredTextType,
      slice_title?: ?PrismicStructuredTextType,
      slice_description?: ?PrismicStructuredTextType,
      slice_image?: ?PrismicImageType,
      slice_background_color?: ?string,
    },
    items: ?Array<?{
      item_name?: ?PrismicStructuredTextType,
      item_link?: ?PrismicLinkType,
      item_icon?: ?PrismicImageType,
    }>,
  },
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    border: '1px solid #DAD5D5',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  primaryWrapper: {
    textAlign: 'left',
    flexGrow: 0,
    maxWidth: '50%',
    flexBasis: '50%',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    },
  },
  secondaryWrapper: {
    flexGrow: 0,
    maxWidth: '50%',
    flexBasis: '50%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  textWrapper: {},
  title: {
    color: theme.palette.common.black,
    '& b, & strong': {
      color: theme.palette.primary.main,
    },
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(2),
    },
  },
  image: {
    width: '100%',
    height: '100%',
    minHeight: 250,
    [theme.breakpoints.up('md')]: {
      minHeight: 300,
    },
  },
  itemsGridContainer: {
    '* + &': {
      paddingTop: theme.spacing(4),
    },
  },
  itemsGridItem: {},
  itemButton: {
    width: '100%',
    display: 'flex',
  },
  itemButtonContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  itemImage: {
    width: '100%',
    height: 'auto',
    maxWidth: 30,
    [theme.breakpoints.up('md')]: {
      maxWidth: 40,
    },
  },
  itemText: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.black,
    textAlign: 'left',
    '$itemButton:hover &': {
      color: theme.palette.primary.main,
    },
  },
});

const RenderChildren = ({ children }: { children: ?React.Node }) => children;

const TabbedCardsSliceItem = ({
  data,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const gridItemProps =
    data.items && data.items?.length === 1 ? { xs: 12 } : { xs: 12, sm: 6 };

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              key={index}
              {...gridItemProps}
              className={classes.itemsGridItem}
            >
              <ButtonBase
                disableTouchRipple
                component={FieldLink}
                field={item.item_link}
                aria-label={item.item_name?.text}
                Fallback={RenderChildren}
                className={classes.itemButton}
              >
                <Fade cascade>
                  <div className={classes.itemButtonContent}>
                    <NodeImage
                      data={item.item_icon}
                      className={classes.itemImage}
                    />
                    <RichText
                      {...item.item_name}
                      className={classes.itemText}
                    />
                  </div>
                </Fade>
              </ButtonBase>
            </Grid>
          ) : null,
        ),
      ),
    [data.items, classes],
  );

  return (
    <div className={classnames(classes.root, className)} {...props}>
      <div className={classes.wrapper}>
        <div className={classes.primaryWrapper}>
          <div className={classes.textWrapper}>
            <RichText
              {...data?.primary?.slice_title}
              className={classes.title}
            />
            <RichText
              {...data?.primary?.slice_description}
              className={classes.description}
            />
          </div>
          <Grid container spacing={2} className={classes.itemsGridContainer}>
            {renderedItems}
          </Grid>
        </div>
        <Fade>
          <div className={classes.secondaryWrapper}>
            <NodeImage
              data={data?.primary?.slice_image}
              className={classes.image}
            />
          </div>
        </Fade>
      </div>
    </div>
  );
};

TabbedCardsSliceItem.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'TabbedCardsSliceItem' })(TabbedCardsSliceItem);
