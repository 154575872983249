// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';

import SliceTitle from '~components/SliceTitle';
import FieldLink from '~plugins/prismic/components/FieldLink';
import { hasRichTextFieldValue } from '~plugins/prismic/components/RichText';
import getButtonVariant from '~components/AppBar/helpers/getButtonVariant';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const CallToActionSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              xs={12}
              sm="auto"
              key={index}
              className={classes.itemsGridItem}
            >
              <Button
                component={FieldLink}
                field={item.item_link}
                aria-label={item.item_link_label?.text}
                variant={getButtonVariant(item.item_link_variant)}
                color="primary"
                className={classes.itemButton}
              >
                {item.item_link_label?.text}
              </Button>
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      {hasRichTextFieldValue(data?.primary?.slice_title) ||
      hasRichTextFieldValue(data?.primary?.slice_description) ? (
        <SliceTitle
          title={data.primary?.slice_title}
          description={data.primary?.slice_description}
          className={classes.sliceTitle}
          idGeneration="title"
        />
      ) : undefined}
      <Grid
        container
        spacing={4}
        justify="center"
        className={classes.itemsGridContainer}
      >
        {renderedItems}
      </Grid>
    </Component>
  );
};

CallToActionSlice.defaultProps = {
  className: undefined,
};

export const StyledCallToActionSlice = withStyles<*, *, Props>(styles, { name: 'CallToActionSlice' })(
CallToActionSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledCallToActionSlice>,
>({ paddingFactor: [3, 0] })(StyledCallToActionSlice);
