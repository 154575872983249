// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import classnames from 'classnames';

import EmbeddedHtml from '~plugins/prismic/components/EmbeddedHtml';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const EmbeddedContentSlice = ({
  data,
  component,
  ContainerProps,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <EmbeddedHtml
        {...(data?.primary?.slice_embedded_content || undefined)}
        className={classes.text}
      />
    </Component>
  );
};

EmbeddedContentSlice.defaultProps = {
  className: undefined,
};

export const StyledEmbeddedContentSlice = withStyles<*, *, Props>(styles, { name: 'EmbeddedContentSlice' })(
EmbeddedContentSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledEmbeddedContentSlice>,
>({ paddingFactor: [2, 0] })(StyledEmbeddedContentSlice);
