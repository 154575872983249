// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import SliceTitle from '~components/SliceTitle';
import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const CardsGridSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              className={classes.itemsGridItem}
            >
              <Fade>
                <Card square variant="outlined" className={classes.itemCard}>
                  <CardActionArea
                    component={FieldLink}
                    field={item.item_link}
                    aria-label={item.item_title?.text}
                    className={classes.itemCardWrapper}
                  >
                    <NodeImage
                      data={item.item_image}
                      className={classes.itemCardMedia}
                    />
                    <CardContent className={classes.itemCardContent}>
                      <Typography variant="h5" className={classes.itemTitle}>
                        {item.item_title?.text}
                      </Typography>
                      <RichText
                        {...item.item_description}
                        className={classes.itemDescription}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Fade>
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        className={classes.sliceTitle}
        idGeneration="title"
      />
      <Grid container spacing={4} className={classes.itemsGridContainer}>
        {renderedItems}
      </Grid>
    </Component>
  );
};

CardsGridSlice.defaultProps = {
  className: undefined,
};

export const StyledCardsGridSlice = withStyles<*, *, Props>(styles, { name: 'CardsGridSlice' })(
CardsGridSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledCardsGridSlice>,
>({ paddingFactor: [4, 0, 6, 0] })(StyledCardsGridSlice);
