// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  tabs: {},
  tab: {
    fontSize: theme.typography.h6.fontSize,
  },
  tabbedItem: {
    '* + &': {
      marginTop: theme.spacing(6),
    },
  },
});

export default styles;
