// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import map from 'lodash/map';
import compact from 'lodash/compact';
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';

import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';
import FieldLink from '~plugins/prismic/components/FieldLink';
import SliceTitle from '~components/SliceTitle';
import StyledCard from '~components/StyledCard';

import type { Props } from './types';
import styles from './styles';

const PricesSlice = ({
  data,
  node,
  className,
  component,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const RenderChildren = ({ children }) => children;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data?.items, (item, index: number) =>
          item ? (
            <Grid
              item
              xs={12}
              sm={4}
              key={index}
              className={classes.itemsGridItem}
            >
              <Fade cascade>
                <StyledCard
                  title={item?.item_title}
                  description={item?.item_description}
                  price={item?.item_price?.text}
                  image={item?.item_image}
                  linkLabel={item?.item_label?.text}
                  link={item?.item_link}
                  className={classes.itemCard}
                  cardActionAreaProps={{
                    component: FieldLink,
                    disableTouchRipple: true,
                    field: item?.item_link,
                    Fallback: RenderChildren,
                  }}
                />
              </Fade>
            </Grid>
          ) : null,
        ),
      ),
    [data?.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        className={classes.sliceTitle}
        idGeneration="title"
      />
      <Grid
        container
        alignItems="stretch"
        spacing={isSmUp ? 4 : 5}
        className={classes.itemsGridContainer}
      >
        {renderedItems}
      </Grid>
    </Component>
  );
};

PricesSlice.defaultProps = {
  className: undefined,
};

export const StyledPricesSlice = withStyles<*, *, Props>(styles, { name: 'PricesSlice' })(PricesSlice);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledPricesSlice>,
>()(StyledPricesSlice);
