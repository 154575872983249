// @flow
import * as React from 'react';

import TextWithImageSlice from '~components/slices/TextWithImageSlice';
import ItemsListSlice from '~components/slices/ItemsListSlice';
import HighlightedItemsSlice from '~components/slices/HighlightedItemsSlice';
import SingleImageSlice from '~components/slices/SingleImageSlice';
import ImageGridSlice from '~components/slices/ImageGridSlice';
import VideoSlice from '~components/slices/VideoSlice';
import HighlightedContentSlice from '~components/slices/HighlightedContentSlice';
import BannerSlice from '~components/slices/BannerSlice';
import RelatedContentSlice from '~components/slices/RelatedContentSlice';
import DownloadFormSlice from '~components/slices/DownloadFormSlice';
import SolutionsSlice from '~components/slices/SolutionsSlice';
import LinksGridSlice from '~components/slices/LinksGridSlice';
import TitleAndDescriptionSlice from '~components/slices/TitleAndDescriptionSlice';
import TabbedCardsSlice from '~components/slices/TabbedCardsSlice';
import ARViewerSlice from '~components/slices/ARViewerSlice';
import PriceSlice from '~components/slices/PriceSlice';
import PricesSlice from '~components/slices/PricesSlice';
import AnimationSlice from '~components/slices/AnimationSlice';
import EmbeddedContentSlice from '~components/slices/EmbeddedContentSlice';
import CallToActionSlice from '~components/slices/CallToActionSlice';
import CardsGridSlice from '~components/slices/CardsGridSlice';
import ContentGridSlice from '~components/slices/ContentGridSlice';
import FloatingButtonSlice from '~components/slices/FloatingButtonSlice';
import IconsGridSlice from '~components/slices/IconsGridSlice';
import IconsCarouselSlice from '~components/slices/IconsCarouselSlice';

import { SLICE_TABBED_CARD_GROUPED_NAME } from '../constants';

export default function useAllNodeBodyComponents() {
  const nodeBodyComponents = {
    text_with_image: TextWithImageSlice,
    items_list: ItemsListSlice,
    highlighted_items: HighlightedItemsSlice,
    single_image: SingleImageSlice,
    image_grid: ImageGridSlice,
    video: VideoSlice,
    highlighted_content: HighlightedContentSlice,
    banner: BannerSlice,
    download_form: DownloadFormSlice,
    related_content: RelatedContentSlice,
    solutions: SolutionsSlice,
    links_grid: LinksGridSlice,
    title_and_description: TitleAndDescriptionSlice,
    [SLICE_TABBED_CARD_GROUPED_NAME]: TabbedCardsSlice,
    ar_viewer: ARViewerSlice,
    price: PriceSlice,
    prices: PricesSlice,
    animation: AnimationSlice,
    embedded_content: EmbeddedContentSlice,
    call_to_action: CallToActionSlice,
    button_cards: CardsGridSlice,
    content_grid: ContentGridSlice,
    floating_button: FloatingButtonSlice,
    icons_grid: IconsGridSlice,
    icons_carousel: IconsCarouselSlice,
  };

  return nodeBodyComponents;
}
