// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    paddingBottom: 0,
  },
  sliceTitle: {},
  itemsGridContainer: {
    width: '100%',
    margin: 'auto',
  },
  itemsGridItem: {
    position: 'relative',
    color: theme.palette.common.white,
    minHeight: 320,
    [theme.breakpoints.up('sm')]: {
      minHeight: 450,
    },
  },
  itemButtonBase: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  itemTextWrapper: {
    zIndex: 1,
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    background: `linear-gradient(0deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0) 100%)`,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(6)}px ${theme.spacing(8)}px`,
    },
  },
  itemText: {
    marginRight: theme.spacing(4),
  },
  itemTitle: {},
  itemDescription: {},
  itemIcon: {
    width: 'fit-content',
    height: 'fit-content',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    '$itemButtonBase:hover &': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  itemBackgroundImage: {
    width: '100%',
    height: '100%',
    '& img': {
      transition: 'all .3s !important',
      '-webkit-transition': 'all .3s !important',
    },
    '$itemButtonBase:hover &': {
      '& img': {
        transform: 'scale(1.05)',
      },
    },
  },
});

export default styles;
