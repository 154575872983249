// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import map from 'lodash/map';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage, {
  hasNodeImageValue,
} from '~plugins/prismic/components/NodeImage';
import StyledNotchedButton from '~components/StyledNotchedButton';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';
import sanitizePrismicButtonVariant from './helpers/sanitizePrismicButtonVariant';
import sanitizePrismicBackgroundColors from './helpers/sanitizePrismicBackgroundColors';

const BannerSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const backgroundImageColor = sanitizePrismicBackgroundColors(
    data.primary?.slice_background_image_color,
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <div
        className={classnames(
          classes.wrapper,
          backgroundImageColor &&
            {
              primary: classes.colorPrimary,
              white: classes.colorWhite,
              grey: classes.colorGrey,
            }[backgroundImageColor],
        )}
      >
        {data.primary?.slice_background_image &&
        data.primary?.slice_background_image?.localFile ? (
          <div className={classes.overlay} />
        ) : null}
        <Grid
          container
          spacing={isSmUp ? 6 : 4}
          alignItems="stretch"
          className={classes.itemsGridContainer}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={7}
            className={classes.itemPrimaryWrapper}
          >
            <RichText
              {...data.primary?.slice_title}
              className={classes.title}
            />
            <RichText
              {...data.primary?.slice_description}
              className={classes.description}
            />
            {data.items ? (
              <div className={classes.buttonWrapper}>
                {map(data.items, (item, index: number) => {
                  const buttonPrismicVariant = sanitizePrismicButtonVariant(
                    item && item.item_link_style,
                  );
                  return item && item.item_link && item.item_label ? (
                    <StyledNotchedButton
                      key={index}
                      color={
                        data.primary?.slice_background_color === 'Primary' ||
                        data.primary?.slice_background_image_color === 'Primary'
                          ? 'secondary'
                          : 'primary'
                      }
                      variant={
                        buttonPrismicVariant
                          ? {
                              contained: 'contained',
                              outlined: 'outlined',
                              text: 'text',
                            }[buttonPrismicVariant]
                          : undefined
                      }
                      component={FieldLink}
                      field={item.item_link}
                      aria-label={item.item_label?.text}
                      className={classes.button}
                    >
                      {item.item_label?.text}
                    </StyledNotchedButton>
                  ) : null;
                })}
              </div>
            ) : null}
          </Grid>
          {hasNodeImageValue(data.primary?.slice_overlapping_image) ? (
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={classes.itemSecondaryWrapper}
            >
              <NodeImage
                data={data.primary?.slice_overlapping_image}
                className={classes.overlappingImage}
              />
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Component>
  );
};

BannerSlice.defaultProps = {
  className: undefined,
};

export const StyledBannerSlice = withStyles<*, *, Props>(styles, { name: 'BannerSlice' })(BannerSlice);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledBannerSlice>,
>()(StyledBannerSlice);
