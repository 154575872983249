// @flow
import { variants } from '../config';
import type { ButtonVariants } from '../types';

export default function sanitizePrismicButtonVariant(
  maybeVariant: ?string,
): ButtonVariants | void {
  return typeof maybeVariant === 'string' && variants[maybeVariant]
    ? variants[maybeVariant]
    : undefined;
}
