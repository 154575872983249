// @flow
import * as React from 'react';

import type { PrismicStructuredTextType } from '~schema';

import sanitizeEmbeddedHtmlFieldValue from '../helpers/sanitizeEmbeddedHtmlFieldValue';

export default function useEmbeddedHtml(
  structuredText: PrismicStructuredTextType,
  maybeRef?: {|
    current: HTMLElement | null,
  |},
) {
  const html = React.useMemo(
    () => sanitizeEmbeddedHtmlFieldValue(structuredText),
    [structuredText.html, structuredText.text],
  );

  const innerRef = React.useRef<HTMLElement | null>(null);
  const ref = maybeRef || innerRef;

  React.useEffect(() => {
    const ref$current = ref.current;
    if (ref$current && typeof document !== 'undefined') {
      const range = document.createRange();
      range.selectNodeContents(ref$current);
      range.deleteContents();
      ref$current.appendChild(range.createContextualFragment(html || ''));
    }
  }, [ref, html]);

  return ref;
}
