// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainerPrimary: {},
  itemPrimaryWrapper: ({ data }) => ({
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      ...(data.primary?.image_position === 'Before'
        ? {
            order: 2,
          }
        : {}),
    },
  }),
  sliceTitle: {},
  itemSecondaryWrapper: {},
  itemSecondaryGridContainery: {},
  itemsGridItem: {
    '& > div': {
      height: '100%',
    },
  },
  itemCard: {
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 4px -1px rgba(0,0,0,0.05), 0px 6px 8px 0px rgba(0,0,0,0.05), 0px 1px 14px 0px rgba(0,0,0,0.05)',
    },
  },
  itemImageWrapper: {
    background: 'linear-gradient(180deg, #E0E0E0 0%, #CECECE 100%)',
    width: '100%',
    position: 'relative',
  },
  itemImage: {
    maxHeight: 180,
    '& img': {
      objectFit: 'contain !important',
      transition: 'all .3s !important',
      '-webkit-transition': 'all .3s !important',
    },
    '$itemCard:hover &': {
      '& img': {
        transform: 'scale(1.05)',
      },
    },
  },
  itemProductTitle: {},
});

export default styles;
