// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Fab from '@material-ui/core/Fab';
import EmailIcon from '@material-ui/icons/Send';
import classnames from 'classnames';

import FieldLink from '~plugins/prismic/components/FieldLink';
import Portal from '~plugins/react/components/Portal';

import type { Props } from './types';
import styles from './styles';

const FloatingButtonSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';

  return (
    <Portal
      target={typeof document === 'undefined' ? null : document.body}
      position="prepend"
    >
      <Component className={classnames(classes.root, className)} {...props}>
        <Fab
          variant="extended"
          color="primary"
          aria-label={data.primary?.slice_label?.text}
          component={FieldLink}
          field={data.primary?.slice_link}
          className={classes.button}
        >
          <EmailIcon className={classes.extendedIcon} />
          {data.primary?.slice_label?.text}
        </Fab>
      </Component>
    </Portal>
  );
};

FloatingButtonSlice.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'FloatingButtonSlice' })(FloatingButtonSlice);
