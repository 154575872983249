// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    background: ({ data }) =>
      data.primary?.slice_background_color === 'Primary'
        ? `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`
        : undefined,
  },
  itemsGridContainer: {},
  itemPrimaryWrapper: {
    textAlign: 'left',
  },
  title: ({ data }) => ({
    ...(data.primary?.slice_background_color === 'Primary'
      ? { color: theme.palette.common.white }
      : { color: theme.palette.common.black }),
  }),
  // $FlowFixMe ---> Reason: Cannot assign function to `styles` because  function is incompatible with `$Shape` of  object type
  description: ({ data }) => ({
    '* + &': {
      marginTop: theme.spacing(2),
      ...(data.primary?.slice_background_color === 'Primary'
        ? { color: theme.palette.common.white }
        : { color: 'inherit' }),
    },
  }),
  downloadForm: ({ data }) => ({
    ...(data.primary?.slice_background_color === 'Primary'
      ? { color: theme.palette.common.white }
      : { color: 'inherit' }),
  }),
});

export default styles;
