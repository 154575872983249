// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const ARViewerSliceItem = React.lazy(() => import('./ARViewerSliceItem'));

const ARViewerSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const isSSR = typeof window === 'undefined';

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid item xs={12} key={index} className={classes.itemsGridItem}>
              <Fade cascade>
                <div className={classes.itemsGridItemWrapper}>
                  {item.item_icon ? (
                    <NodeImage
                      data={item.item_icon}
                      className={classes.itemImage}
                    />
                  ) : null}
                  <div className={classes.itemTextWrapper}>
                    {item.item_title ? (
                      <RichText
                        {...item.item_title}
                        className={classes.itemTitle}
                      />
                    ) : null}
                    {item.item_description ? (
                      <RichText
                        {...item.item_description}
                        className={classes.itemDescription}
                      />
                    ) : null}
                  </div>
                </div>
              </Fade>
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <div className={classes.wrapper}>
        <div className={classes.primaryWrapper}>
          <div className={classes.textWrapper}>
            <RichText
              {...data.primary?.slice_title}
              className={classes.title}
            />
            <RichText
              {...data.primary?.slice_description}
              className={classes.description}
            />
          </div>
          {renderedItems.length ? (
            <Grid container spacing={4} className={classes.itemsGridContainer}>
              {renderedItems}
            </Grid>
          ) : null}
        </div>
        {!isSSR ? (
          <React.Suspense fallback={<div />}>
            <ARViewerSliceItem
              glbModel={data.primary?.slice_glb_model_link?.url}
              usdzModel={data.primary?.select_usdz_model_link?.url}
              modelAlt={data.primary?.slice_title?.text}
              showViewerButton={data.primary?.viewer_button}
              {...(data.primary?.slice_background_image &&
              data.primary?.slice_background_image?.localFile?.url
                ? {
                    hasBackgroundImage: true,
                    backgroundImage: data.primary?.slice_background_image,
                  }
                : null)}
              className={classes.modelViewer}
            />
          </React.Suspense>
        ) : null}
      </div>
    </Component>
  );
};

ARViewerSlice.defaultProps = {
  className: undefined,
};

export const StyledARViewerSlice = withStyles<*, *, Props>(styles, { name: 'ARViewerSlice' })(
ARViewerSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledARViewerSlice>,
>()(StyledARViewerSlice);
