// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(1.5),
    right: theme.spacing(2),
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  button: {
    borderRadius: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(2),
  },
});

export default styles;
