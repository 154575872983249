// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import SliceTitle from '~components/SliceTitle';
import StyledNotchedButton from '~components/StyledNotchedButton';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const LinksGridSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              className={classes.itemsGridItem}
            >
              <Fade cascade big>
                <div className={classes.itemButtonWrapper}>
                  <StyledNotchedButton
                    component={FieldLink}
                    field={item?.item_link}
                    variant="outlined"
                    fullWidth
                    endIcon={<ArrowForward className={classes.itemIcon} />}
                    aria-label={item.item_link_label?.text}
                    className={classes.itemButton}
                  >
                    {item.item_link_label?.text}
                  </StyledNotchedButton>
                </div>
              </Fade>
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        className={classes.sliceTitle}
        idGeneration="title"
      />
      <Grid container spacing={4} className={classes.itemsGridContainer}>
        {renderedItems}
      </Grid>
    </Component>
  );
};

LinksGridSlice.defaultProps = {
  className: undefined,
};

export const StyledLinksGridSlice = withStyles<*, *, Props>(styles, { name: 'LinksGridSlice' })(
LinksGridSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledLinksGridSlice>,
>()(StyledLinksGridSlice);
