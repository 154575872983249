// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  sliceTitle: {
    textAlign: 'initial',
  },
  itemsGridContainer: {},
  itemGridItem: ({ data }) => ({
    maxHeight: 200,
    ...(data.primary?.max_line_items === '5'
      ? {
          [theme.breakpoints.up('md')]: {
            maxWidth: 'calc(100% / 5)',
            flexBasis: 'calc(100% / 5)',
          },
        }
      : null),
  }),
  itemGridItemWithCaption: {
    paddingBottom: `${theme.spacing(8)}px !important`,
  },
  itemButton: {
    width: '100%',
    height: '100%',
    display: 'block',
    '& .itemIcon': {
      minWidth: '100%',
    },
  },
  itemIcon: {
    height: '100%',
    '& img': {
      objectFit: 'contain !important',
    },
  },
  itemName: {
    paddingTop: theme.spacing(1.5),
  },
});

export default styles;
