// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import { Waypoint } from 'react-waypoint';

import VideoPlayer from '~components/VideoPlayer';
import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import { hasRichTextFieldValue } from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props, VideoWidth } from './types';
import styles from './styles';
import sanitizeImageWidth from '../SingleImageSlice/helpers/sanitizeImageWidth';

const VideoSlice = ({
  data,
  node,
  component,
  className,
  classes,
  ContainerProps,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const [playing, setPlaying] = React.useState(false);

  const getContainerWidth = (width: VideoWidth | null) => {
    const containerWidth =
      width &&
      {
        Fullwidth: {
          maxWidth: false,
          disableGutters: true,
        },
        Contained: undefined,
      }[sanitizeImageWidth(width) || 'Contained'];
    return containerWidth === undefined ? undefined : containerWidth;
  };

  const handleEnterViewport = React.useCallback(() => {
    setPlaying(true);
  }, [setPlaying]);

  const handleExitViewport = React.useCallback(() => {
    setPlaying(false);
  }, [setPlaying]);

  const ConditionalWaypoint = ({ autoplay, waypoint, children }) =>
    React.useMemo(() => (autoplay ? waypoint(children) : children), [
      autoplay,
      waypoint,
      children,
    ]);

  return (
    <Component
      ContainerProps={React.useMemo(() =>
        getContainerWidth(data.primary?.video_width || 'Contained'),
      )}
      className={classnames(classes.root, className)}
      {...props}
    >
      <ConditionalWaypoint
        autoplay={data?.primary?.autoplay}
        waypoint={children => (
          <Waypoint onEnter={handleEnterViewport} onLeave={handleExitViewport}>
            <div className={classes.videoWrapper}>{children}</div>
          </Waypoint>
        )}
      >
        <VideoPlayer
          className={classes.video}
          url={data?.primary?.slice_link?.url}
          controls
          muted={data?.primary?.autoplay}
          light={
            !data?.primary?.autoplay &&
            (data?.primary?.slice_image_placeholder?.localFile?.childImageSharp
              ?.fluid?.src ||
              data?.primary?.slice_image_placeholder?.fluid?.src)
          }
          playing={
            data?.primary?.autoplay
              ? playing
              : data?.primary?.slice_image_placeholder !== undefined
          }
          playsinline={
            data?.primary?.autoplay
              ? playing
              : data?.primary?.slice_image_placeholder !== undefined
          }
          playIcon={
            !data?.primary?.autoplay &&
            data?.primary?.slice_image_placeholder && (
              <IconButton
                aria-label="play"
                color="primary"
                className={classes.playButton}
              >
                <PlayArrowIcon className={classes.playIcon} />
              </IconButton>
            )
          }
          width="100%"
          height="100%"
        />
      </ConditionalWaypoint>
      {hasRichTextFieldValue(data?.primary?.caption) ? (
        <Container>
          <RichTextTypography
            {...data?.primary?.caption}
            variant="caption"
            className={classes.caption}
          />
        </Container>
      ) : null}
    </Component>
  );
};

VideoSlice.defaultProps = {
  className: undefined,
};

export const StyledVideoSlice = withStyles<*, *, Props>(styles, { name: 'VideoSlice' })(VideoSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledVideoSlice>,
>({ paddingFactor: [2, 0] })(StyledVideoSlice);
