// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classnames from 'classnames';
import map from 'lodash/map';

import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';
import TabbedCardsSliceItem from './TabbedCardsSliceItem';

const TabbedCardsSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChange = React.useCallback(
    (unusedEvent: Event, newValue: number) => {
      setCurrentTab(newValue);
    },
    [setCurrentTab],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="off"
        className={classes.tabs}
        // increase tabs text size
      >
        {map(data.items, (slice, index: number) => (
          <Tab
            key={index}
            label={slice?.primary?.slice_tab_name?.text}
            aria-label={slice?.primary?.slice_tab_name?.text}
            className={classes.tab}
          />
        ))}
      </Tabs>
      {map(data.items, (slice, index: number) =>
        currentTab === index && slice ? (
          <TabbedCardsSliceItem
            key={index}
            data={slice}
            className={classes.tabbedItem}
          />
        ) : null,
      )}
    </Component>
  );
};

TabbedCardsSlice.defaultProps = {
  className: undefined,
};

export const StyledTabbedCardsSlice = withStyles<*, *, Props>(styles, { name: 'TabbedCardsSlice' })(
TabbedCardsSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledTabbedCardsSlice>,
>()(StyledTabbedCardsSlice);
