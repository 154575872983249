// @flow
import * as React from 'react';
import classnames from 'classnames';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';
import NodeImage from '~plugins/prismic/components/NodeImage';
import RichText from '~plugins/prismic/components/RichText';

import SliceTitle from '~components/SliceTitle';
import type { Props } from './types';
import styles from './styles';

const HighlightedContentSlice = ({
  data,
  node,
  index: sliceIndex,
  className,
  classes,
  component,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const Component = component || Container;
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const gridItemProps =
    data.items && data.items?.length === 1 ? { xs: 12 } : { xs: 12, sm: 6 };

  const RenderChildren = ({ children }) => children;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              {...gridItemProps}
              key={index}
              className={classes.itemsGridItem}
            >
              <Fade cascade>
                <Card variant="outlined" square className={classes.itemCard}>
                  <CardActionArea
                    disableTouchRipple
                    component={FieldLink}
                    field={item.item_link}
                    aria-label={item.item_label?.text || item.item_title?.text}
                    Fallback={RenderChildren}
                  >
                    {item.item_image ? (
                      <div className={classes.itemImageWrapper}>
                        <NodeImage
                          data={item.item_image}
                          className={classes.itemImage}
                        />
                      </div>
                    ) : null}
                    <CardContent>
                      <RichText
                        {...item.item_title}
                        className={classes.itemProductTitle}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Fade>
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root)} {...props}>
      <Grid
        container
        alignItems="center"
        spacing={isSmUp ? 6 : 4}
        className={classes.itemsGridContainerPrimary}
      >
        <Grid item xs={12} md={6} className={classes.itemPrimaryWrapper}>
          <SliceTitle
            title={data.primary?.slice_title}
            description={data.primary?.slice_description}
            disablePaddingBottom
            className={classes.sliceTitle}
            contentAlign="left"
            idGeneration="title"
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.itemSecondaryWrapper}>
          <Grid
            container
            item
            alignItems="stretch"
            spacing={isSmUp ? 4 : 3}
            className={classes.itemSecondaryGridContainery}
          >
            {renderedItems}
          </Grid>
        </Grid>
      </Grid>
    </Component>
  );
};

export const StyledHighlightedContentSlice = withStyles<*, *, Props>(styles, { name: 'HighlightedContentSlice' })(
HighlightedContentSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledHighlightedContentSlice>,
>()(StyledHighlightedContentSlice);
