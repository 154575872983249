// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import RichText, {
  hasRichTextFieldValue,
} from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';
import VideoPlayer from '~components/VideoPlayer';

import type { Props } from './types';
import styles from './styles';
import TextWithImageItemSlice from './TextWithImageItemSlice';

const TextWithImageSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isImageOverlapping = data.primary?.overlapping_image;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item && hasRichTextFieldValue(item.item_highlighted_text) ? (
            <TextWithImageItemSlice
              key={index}
              highlightedText={item.item_highlighted_text}
              highlightedTextPosition={data.primary?.image_position}
              className={classnames(classes.itemHighlightedTextItem, {
                [classes.highlightedItem]:
                  data.items && data.items?.length === 1,
                [classes.highlightedItems]:
                  data.items && data.items?.length === 2,
              })}
            />
          ) : null,
        ),
      ),
    [data.items],
  );
  
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Grid
        container
        spacing={isSmUp ? 6 : 4}
        alignItems="center"
        className={classes.itemsGridContainer}
      >
        <Grid item xs={12} sm={6} className={classes.itemPrimaryWrapper}>
          <RichText {...data.primary?.slice_title} className={classes.title} />
          <RichText
            {...data.primary?.slice_description}
            className={classes.description}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classnames(classes.itemSecondaryWrapper, {
            [classes.itemSecondaryWrapperVideo]: !!data?.primary?.slice_video
              ?.url,
          })}
        >
          <Fade
            distance="40%"
            {...(data.primary?.image_position === 'Before'
              ? { left: true }
              : { right: true })}
          >
            {data?.primary?.slice_video?.url ? (
              <VideoPlayer
                url={data.primary?.slice_video?.url}
                {...(!data.primary?.slice_video_autoplay
                  ? {
                      controls: true,
                      muted: false,
                    }
                  : {
                      controls: false,
                      muted: true,
                      loop: true,
                      playing: true,
                      playsinline: true,
                    })}
                width="100%"
                height="100%"
              />
            ) : (
              <NodeImage
                data={data.primary?.slice_image}
                className={classnames(classes.image, {
                  [classes.imageOverlapping]: isImageOverlapping,
                })}
              />
            )}
          </Fade>
          {renderedItems}
        </Grid>
      </Grid>
    </Component>
  );
};

TextWithImageSlice.defaultProps = {
  className: undefined,
};

export const StyledTextWithImageSlice = withStyles<*, *, Props>(styles, {
  name: 'TextWithImageSlice',
})(TextWithImageSlice);
export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledTextWithImageSlice>,
>()(StyledTextWithImageSlice);
