// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';

import RichText from '~plugins/prismic/components/RichText';
import type { PrismicStructuredTextType } from '~schema';

export type ClassKey = 'root' | 'wrapper' | 'text';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  highlightedText?: ?PrismicStructuredTextType,
  highlightedTextPosition?: ?string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2))',
  },
  wrapper: {
    position: 'relative',
    maxWidth: 250,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    clipPath:
      'polygon(0% 10px,10px 0%,calc(100% - 10px) 0%,100% 10px,100% calc(100% - 10px),calc(100% - 10px) 100%,10px 100%,0 calc(100% - 10px))',
  },
  text: {
    textAlign: 'left',
  },
});

const TextWithImageItemSlice = ({
  highlightedText,
  highlightedTextPosition,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <Fade
        duration={800}
        {...(highlightedTextPosition === 'After'
          ? { right: true }
          : { left: true })}
      >
        <div className={classes.wrapper}>
          <RichText {...highlightedText} className={classes.text} />
        </div>
      </Fade>
    </div>
  );
};

TextWithImageItemSlice.defaultProps = {
  className: undefined,
  highlightedText: undefined,
  highlightedTextPosition: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'TextWithImageItemSlice' })(TextWithImageItemSlice);
