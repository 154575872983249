// @flow
import * as React from 'react';
import classnames from 'classnames';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import SliceTitle from '~components/SliceTitle';

import type { Props, ContainerWidth } from './types';
import sanitizeContainerWidth from './helpers/sanitizeContainerWidth';
import styles from './styles';

const TitleAndDescriptionSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  const getContainerWidth = (width: ContainerWidth) => {
    const containerWidth =
      width &&
      {
        Fullwidth: 'lg',
        Contained: undefined,
      }[sanitizeContainerWidth(width) || 'Contained'];
    return containerWidth;
  };

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        titleImage={data.primary?.slice_title_image}
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        {...(data.primary?.container_width === 'Contained' ||
        data.primary?.container_width === null
          ? { contentAlign: 'left' }
          : undefined)}
        containerWidth={getContainerWidth(data.primary?.container_width)}
        disablePaddingBottom
        className={classes.sliceTitle}
        idGeneration="title"
      />
    </Component>
  );
};

export const StyledTitleAndDescriptionSlice = withStyles<*, *, Props>(styles, { name: 'TitleAndDescriptionSlice' })(
TitleAndDescriptionSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledTitleAndDescriptionSlice>,
>({ paddingFactor: [2, 0] })(StyledTitleAndDescriptionSlice);
