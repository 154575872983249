// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import StyledNotchedButton from '~components/StyledNotchedButton';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';
import sanitizePrismicButtonVariant from './helpers/sanitizePrismicButtonVariant';

const PriceSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isImageOverlapping = data.primary?.overlapping_image;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data?.items, (item, index: number) => {
          const buttonPrismicVariant = sanitizePrismicButtonVariant(
            item && item.item_link_style,
          );
          return item ? (
            <StyledNotchedButton
              key={index}
              color={
                data.primary?.slice_background_color === 'Primary' ||
                data.primary?.slice_background_image_color === 'Primary'
                  ? 'secondary'
                  : 'primary'
              }
              variant={
                buttonPrismicVariant
                  ? {
                      contained: 'contained',
                      outlined: 'outlined',
                      text: 'text',
                    }[buttonPrismicVariant]
                  : undefined
              }
              component={FieldLink}
              field={item.item_link}
              aria-label={item.item_label?.text}
              className={classes.button}
            >
              {item.item_label?.text}
            </StyledNotchedButton>
          ) : null;
        }),
      ),
    [data?.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <div className={classes.wrapper}>
        <Grid
          container
          spacing={isSmUp ? 6 : 4}
          alignItems="center"
          className={classes.itemsGridContainer}
        >
          <Grid
            item
            xs={12}
            sm={5}
            md={6}
            className={classes.itemPrimaryWrapper}
          >
            <NodeImage
              data={data.primary?.slice_image}
              className={classnames(classes.image, {
                [classes.imageOverlapping]: isImageOverlapping,
              })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={6}
            className={classes.itemSecondaryWrapper}
          >
            <div className={classes.titleWrapper}>
              <RichText
                {...data.primary?.slice_title}
                className={classes.title}
              />
              {data.primary?.slice_price && data.primary?.slice_price?.text ? (
                <div className={classes.priceWrapper}>
                  <Typography variant="h6" className={classes.price}>
                    {data.primary?.slice_price?.text}
                  </Typography>
                </div>
              ) : null}
            </div>
            <RichText
              {...data.primary?.slice_description}
              className={classes.description}
            />
            {data.items ? (
              <div className={classes.buttonWrapper}>{renderedItems}</div>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Component>
  );
};

PriceSlice.defaultProps = {
  className: undefined,
};

export const StyledPriceSlice = withStyles<*, *, Props>(styles, { name: 'PriceSlice' })(PriceSlice);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledPriceSlice>,
>()(StyledPriceSlice);
