// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  sliceTitle: { textAlign: 'left' },
  itemsGridContainer: {},
  itemsGridItem: {},
  itemsGridItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  itemImage: {
    width: '100%',
    height: 'auto',
    maxWidth: 70,
  },
  itemTitle: {
    '* + &': {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%',
    },
    '& > *': {
      color: theme.palette.common.black,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },
  itemDescription: {
    '* + &': {
      marginTop: theme.spacing(1),
    },
    '& > *': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
});

export default styles;
