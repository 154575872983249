// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import map from 'lodash/map';
import compact from 'lodash/compact';
import classnames from 'classnames';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import NodeImage from '~plugins/prismic/components/NodeImage';
import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import FieldLink from '~plugins/prismic/components/FieldLink';
import { hasRichTextFieldValue } from '~plugins/prismic/components/RichText';
import SliceTitle from '~components/SliceTitle';

import type { Props } from './types';
import styles from './styles';

const IconsGridSlice = ({
  data,
  className,
  classes,
  component,
  ContainerProps,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const lineItems = data.primary?.max_line_items;
  const gridItemProps = {
    '1': { xs: 12 },
    '2': { xs: 6, sm: 6 },
    '3': { xs: 6, sm: 4 },
    '4': { xs: 6, sm: 6, md: 3 },
    '5': { xs: 6, sm: 6 },
    '6': { xs: 6, sm: 4, md: 2 },
  }[lineItems || '2'];

  const RenderChildren = ({ children }) => children;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) => {
          return item ? (
            <Grid
              item
              key={index}
              {...gridItemProps}
              className={classnames(classes.itemGridItem, {
                [classes.itemGridItemWithCaption]: hasRichTextFieldValue(
                  item?.item_name,
                ),
              })}
            >
              <ButtonBase
                disableTouchRipple
                component={FieldLink}
                field={item.item_link}
                aria-label={item.item_name?.text}
                Fallback={RenderChildren}
                className={classes.itemButton}
              >
                <NodeImage data={item.item_icon} className={classes.itemIcon} />
                {item?.item_name ? (
                  <Container>
                    <RichTextTypography
                      {...item?.item_name}
                      variant="caption"
                      className={classes.itemName}
                    />
                  </Container>
                ) : null}
              </ButtonBase>
            </Grid>
          ) : null;
        }),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        contentAlign="left"
        className={classes.sliceTitle}
      />
      <Grid
        container
        alignItems="stretch"
        spacing={3}
        className={classes.itemsGridContainer}
      >
        {renderedItems}
      </Grid>
    </Component>
  );
};

IconsGridSlice.defaultProps = {
  className: undefined,
};

export const StyledIconsGridSlice = withStyles<*, *, Props>(styles, { name: 'IconsGridSlice' })(
IconsGridSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledIconsGridSlice>,
>()(StyledIconsGridSlice);
