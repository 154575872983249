// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {},
  itemGridItem: { maxHeight: 400 },
  itemGridItemWithCaption: {
    paddingBottom: `${theme.spacing(8)}px !important`,
  },
  itemImage: {
    height: '100%',
    '& img': {
      objectFit: 'contain',
    },
  },
  itemCaption: {
    textAlign: 'left',
    paddingTop: theme.spacing(1.5),
  },
});

export default styles;
