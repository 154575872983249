// @flow
import type { Styles } from './types';

import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {},
  itemPrimaryWrapper: ({ data }) => ({
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      ...(data.primary?.image_position === 'Before'
        ? {
            order: 2,
          }
        : {}),
    },
  }),
  itemSecondaryWrapper: {
    position: 'relative',
  },
  itemSecondaryWrapperVideo: {
    aspectRatio: '16/9',
    '& > div': {
      width: '100%',
      height: '100%',
    },
  },
  title: {
    color: theme.palette.common.black,
    '& b, & strong': {
      color: theme.palette.primary.main,
    },
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiListText-padding': {
      paddingTop: 16,
      paddingBottom: 16,
    },
    '& li:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  image: {
    width: ({ data }) => (data.primary?.overlapping_image ? '110%' : '100%'),
    minHeight: 250,
    [theme.breakpoints.up('md')]: {
      minHeight: 300,
    },
  },
  imageOverlapping: ({ data }) => ({
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          ...(data.primary?.image_position === 'After'
            ? { marginLeft: theme.spacing(getBreakPointFactor(breakpoint) * 2) }
            : {
                marginLeft: theme.spacing(-getBreakPointFactor(breakpoint)) * 2,
              }),
        },
      }),
      {},
    ),
  }),
  itemHighlightedTextItem: {
    position: 'absolute',
  },
  highlightedItem: ({ data }) => ({
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          ...(data.primary?.image_position === 'After'
            ? {
                bottom: theme.spacing(getBreakPointFactor(breakpoint) * 2),
                right: theme.spacing(-getBreakPointFactor(breakpoint) / 1.5),
              }
            : {
                bottom: theme.spacing(getBreakPointFactor(breakpoint) * 2),
                left: theme.spacing(-getBreakPointFactor(breakpoint) / 1.5),
              }),
        },
      }),
      {},
    ),
  }),
  highlightedItems: ({ data }) => ({
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          ...(data.primary?.image_position === 'After'
            ? {
                '&:nth-child(even)': {
                  top: theme.spacing(getBreakPointFactor(breakpoint) * 2),
                  left: theme.spacing(-getBreakPointFactor(breakpoint) / 1.5),
                },
                '&:nth-child(odd)': {
                  bottom: theme.spacing(getBreakPointFactor(breakpoint) * 2),
                  right: theme.spacing(-getBreakPointFactor(breakpoint) / 1.5),
                },
              }
            : {
                '&:nth-child(even)': {
                  top: theme.spacing(getBreakPointFactor(breakpoint) * 2),
                  right: theme.spacing(-getBreakPointFactor(breakpoint) / 1.5),
                },
                '&:nth-child(odd)': {
                  bottom: theme.spacing(getBreakPointFactor(breakpoint) * 2),
                  left: theme.spacing(-getBreakPointFactor(breakpoint) / 1.5),
                },
              }),
        },
      }),
      {},
    ),
  }),
});

export default styles;
