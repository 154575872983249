// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const ItemsListSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isImageOverlapping = data.primary?.overlapping_image;
  const gridItemProps =
    data.items && data.items?.length === 1 ? { xs: 12 } : { xs: 12, sm: 6 };

  const RenderChildren = ({ children }) => children;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              key={index}
              {...gridItemProps}
              className={classes.itemsGridItem}
            >
              <ButtonBase
                disableTouchRipple
                component={FieldLink}
                field={item.item_link}
                aria-label={item.item_name?.text}
                Fallback={RenderChildren}
                className={classes.itemButton}
              >
                <Fade cascade>
                  <div className={classes.itemButtonContent}>
                    <NodeImage
                      data={item.item_icon}
                      className={classes.itemImage}
                    />
                    <RichText
                      {...item.item_name}
                      className={classes.itemText}
                    />
                  </div>
                </Fade>
              </ButtonBase>
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Grid
        container
        spacing={isSmUp ? 6 : 4}
        alignItems="center"
        className={classes.itemsGridContainer}
      >
        <Grid item xs={12} md={6} className={classes.itemPrimaryWrapper}>
          <div className={classes.textWrapper}>
            <RichText
              {...data.primary?.slice_title}
              className={classes.title}
            />
            <RichText
              {...data.primary?.slice_description}
              className={classes.description}
            />
          </div>
          <Grid container item spacing={2} className={classes.listContainer}>
            {renderedItems}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.itemSecondaryWrapper}>
          <Fade
            distance="40%"
            {...(data.primary?.image_position === 'Before'
              ? { left: true }
              : { right: true })}
          >
            <NodeImage
              data={data.primary?.slice_image}
              className={classnames(classes.image, {
                [classes.imageOverlapping]: isImageOverlapping,
              })}
            />
          </Fade>
        </Grid>
      </Grid>
    </Component>
  );
};

ItemsListSlice.defaultProps = {
  className: undefined,
};

export const StyledItemsListSlice = withStyles<*, *, Props>(styles, { name: 'ItemsListSlice' })(
ItemsListSlice,
);
export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledItemsListSlice>,
>()(StyledItemsListSlice);
