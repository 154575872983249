// @flow
/* eslint-disable no-use-before-define */
import * as React from 'react';
import reduce from 'lodash/reduce';

import type { BodySlice, BodySlice$Items } from '../components/NodeBody/types';

export type BodySliceAny = BodySlice<*, *, *>;

export type Config = {|
  slices: ?Array<?BodySliceAny>,
  shouldGroup: BodySliceAny => boolean,
  getGroupedType: BodySliceAny => string,
|};

export default function useGroupedSlicesByType({
  slices,
  shouldGroup,
  getGroupedType,
}: Config): Array<?BodySliceAny> {
  return React.useMemo(() => {
    const getGroupedSlice = curryGetGroupedSlice({ getGroupedType });
    const getPreviousSlices = curryGetPreviousSlices({ getGroupedType });
    return reduce(
      slices || [],
      (acc, slice) =>
        slice && shouldGroup(slice)
          ? [...getPreviousSlices(acc, slice), getGroupedSlice(acc, slice)]
          : [...acc, slice],
      [],
    );
  }, [slices, shouldGroup, getGroupedType]);
}

function curryGetPreviousOrNewGroupedSlice({ getGroupedType }) {
  return (slices, slice): BodySlice$Items<*, *> => {
    const previousSlice = getLastSlice(slices);
    const groupedType = getGroupedType(slice);
    return previousSlice &&
      previousSlice.slice_type === groupedType &&
      Array.isArray(previousSlice.items)
      ? previousSlice
      : {
          slice_type: groupedType,
          items: [],
        };
  };
}

function curryGetGroupedSlice({ getGroupedType }) {
  const getPreviousOrNewGroupedSlice = curryGetPreviousOrNewGroupedSlice({
    getGroupedType,
  });
  return (slices, slice): BodySlice$Items<*, *> => {
    const groupedSlice = getPreviousOrNewGroupedSlice(slices, slice);
    return {
      ...groupedSlice,
      items: [...(groupedSlice.items || []), slice],
    };
  };
}

function curryGetPreviousSlices({ getGroupedType }) {
  return (slices, slice) => {
    const previousSlice = getLastSlice(slices);
    return previousSlice && previousSlice.slice_type === getGroupedType(slice)
      ? slices.slice(0, slices.length - 1)
      : slices;
  };
}

function getLastSlice(slices): ?BodySliceAny {
  return slices[slices.length - 1];
}
