// @flow
import * as React from 'react';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import NodeImage from '~plugins/prismic/components/NodeImage';
import RichText from '~plugins/prismic/components/RichText';

import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';
import SliceTitle from '~components/SliceTitle';

import type { Props } from './types';
import styles from './styles';

const HighlightedItemsSlice = ({
  data,
  node,
  index: sliceIndex,
  className,
  classes,
  component,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const gridItemProps = { xs: 6, md: 4 };

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              key={index}
              className={classes.itemsGridItem}
              {...gridItemProps}
            >
              <Fade cascade>
                <div className={classes.itemsGridItemWrapper}>
                  {item.item_icon ? (
                    <NodeImage
                      data={item.item_icon}
                      className={classes.itemImage}
                    />
                  ) : null}
                  {item.item_title ? (
                    <RichText
                      {...item.item_title}
                      className={classes.itemTitle}
                    />
                  ) : null}
                  {item.item_description ? (
                    <RichText
                      {...item.item_description}
                      className={classes.itemDescription}
                    />
                  ) : null}
                </div>
              </Fade>
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        className={classes.sliceTitle}
        contentAlign="left"
        idGeneration="title"
      />
      {renderedItems.length ? (
        <Grid
          container
          alignItems="baseline"
          justify="center"
          spacing={isSmUp ? 8 : 6}
          className={classes.itemsGridContainer}
        >
          {renderedItems}
        </Grid>
      ) : null}
    </Component>
  );
};

HighlightedItemsSlice.defaultProps = {
  className: undefined,
};

export const StyledHighlightedItemsSlice = withStyles<*, *, Props>(styles, { name: 'HighlightedItemsSlice' })(
HighlightedItemsSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledHighlightedItemsSlice>,
>()(StyledHighlightedItemsSlice);
