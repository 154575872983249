// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Container from '@material-ui/core/Container';
import ButtonBase from '@material-ui/core/ButtonBase';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import map from 'lodash/map';
import compact from 'lodash/compact';
import classnames from 'classnames';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import NodeImage from '~plugins/prismic/components/NodeImage';
import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import FieldLink from '~plugins/prismic/components/FieldLink';
import SliceTitle from '~components/SliceTitle';

import type { Props } from './types';
import styles from './styles';

const IconsCarouselSlice = ({
  data,
  className,
  classes,
  component,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const Component = component || Container;

  const numberOfVisibleSlides = () => {
    if (isXsDown) {
      return 2;
    }
    if (isSmDown) {
      return 3;
    }
    return 5;
  };

  const RenderChildren = ({ children }) => children;

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) => {
          return item ? (
            <Slide
              key={index}
              index={index}
              aria-label={`slide-${index}`}
              className={classes.carouselItem}
            >
              <ButtonBase
                disableTouchRipple
                component={FieldLink}
                field={item.item_link}
                aria-label={item.item_name?.text}
                Fallback={RenderChildren}
                className={classes.itemButton}
              >
                <NodeImage data={item.item_icon} className={classes.itemIcon} />
                {item?.item_name ? (
                  <Container>
                    <RichTextTypography
                      {...item?.item_name}
                      variant="caption"
                      className={classes.itemName}
                    />
                  </Container>
                ) : null}
              </ButtonBase>
            </Slide>
          ) : null;
        }),
      ),
    [data.items, classes],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        contentAlign="left"
        className={classes.sliceTitle}
      />
      <div>
        <CarouselProvider
          visibleSlides={numberOfVisibleSlides()}
          totalSlides={renderedItems.length}
          step={numberOfVisibleSlides()}
          naturalSlideWidth={250}
          naturalSlideHeight={200}
          isPlaying
          infinite
          className={classes.carousel}
        >
          <Slider aria-label="slider">{renderedItems}</Slider>
          <ButtonBack className={classes.buttonBack}>
            <ChevronLeft />
          </ButtonBack>
          <ButtonNext className={classes.buttonNext}>
            <ChevronRight />
          </ButtonNext>
        </CarouselProvider>
      </div>
    </Component>
  );
};

IconsCarouselSlice.defaultProps = {
  className: undefined,
};

export const StyledIconsCarouselSlice = withStyles<*, *, Props>(styles, { name: 'IconsCarouselSlice' })(
IconsCarouselSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledIconsCarouselSlice>,
>()(StyledIconsCarouselSlice);
