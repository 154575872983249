// @flow
import type { Styles } from './types';

const styles: Styles = unusedTheme => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  sliceTitle: {},
  itemsGridContainer: {},
  itemsGridItem: {
    '& div.react-reveal': {
      height: '100%',
    },
  },
  itemCard: {
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 5px -1px rgba(0,0,0,0.1), 0px 6px 10px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
});

export default styles;
