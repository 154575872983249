// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import map from 'lodash/map';
import compact from 'lodash/compact';
import classnames from 'classnames';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import NodeImage from '~plugins/prismic/components/NodeImage';
import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import { hasRichTextFieldValue } from '~plugins/prismic/components/RichText';
import sanitizeImageWidth from '../SingleImageSlice/helpers/sanitizeImageWidth';

import type { Props, ImageWidth } from './types';
import styles from './styles';

const ImageGridSlice = ({
  data,
  className,
  classes,
  component,
  ContainerProps,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const lineItems = data.primary?.max_line_items;
  const gridItemProps = {
    '2': { xs: 12, sm: 6 },
    '3': { xs: 12, sm: 4 },
    '4': { xs: 12, sm: 6, md: 3 },
  }[lineItems || '2'];

  const getContainerWidth = (width: ImageWidth | null) => {
    const containerWidth =
      width &&
      {
        Fullwidth: {
          maxWidth: false,
          disableGutters: true,
        },
        Contained: undefined,
      }[sanitizeImageWidth(width) || 'Contained'];
    return containerWidth === undefined ? undefined : containerWidth;
  };

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              item
              key={index}
              {...gridItemProps}
              className={classnames(classes.itemGridItem, {
                [classes.itemGridItemWithCaption]: hasRichTextFieldValue(
                  item?.item_caption,
                ),
              })}
            >
              <NodeImage data={item.item_image} className={classes.itemImage} />
              {item?.item_caption ? (
                <Container>
                  <RichTextTypography
                    {...item?.item_caption}
                    variant="caption"
                    className={classes.itemCaption}
                  />
                </Container>
              ) : null}
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component
      ContainerProps={React.useMemo(() =>
        getContainerWidth(data?.primary?.image_width || 'Contained'),
      )}
      className={classnames(classes.root, className)}
      {...props}
    >
      <Grid
        container
        alignItems="stretch"
        spacing={
          getContainerWidth(data?.primary?.image_width || 'Contained') ? 0 : 3
        }
        className={classes.itemsGridContainer}
      >
        {renderedItems}
      </Grid>
    </Component>
  );
};

ImageGridSlice.defaultProps = {
  className: undefined,
};

export const StyledImageGridSlice = withStyles<*, *, Props>(styles, { name: 'ImageGridSlice' })(
ImageGridSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledImageGridSlice>,
>({ paddingFactor: [2, 0] })(StyledImageGridSlice);
