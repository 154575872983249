// @flow
import * as React from 'react';

import hasRichTextFieldValue from '../../helpers/hasRichTextFieldValue';
import useEmbeddedHtml from './hooks/useEmbeddedHtml';

import type { Props } from './types';

const EmbeddedHtml = ({
  Component,
  html,
  raw,
  text,
  className,
  classes,
  onDocumentLinkClick,
  ...props
}: Props): React.Node => {
  const ref = useEmbeddedHtml({ html, raw, text });

  return Component &&
    hasRichTextFieldValue({ html, text }, { operator: 'or' }) ? (
    <Component {...props} ref={ref} />
  ) : null;
};

EmbeddedHtml.defaultProps = {
  Component: 'div',
  className: undefined,
};

export default EmbeddedHtml;
